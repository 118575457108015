import {
  Button,
  Checkbox,
  Div,
  FormItem,
  FormLayout,
  Group,
  Panel,
  PanelHeader,
  Radio,
  Text,
  Input,
} from "@vkontakte/vkui";
import axios from "axios";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { config } from "../config";
import { StoreContext } from "../store/Store";

export const StartingQuizPanel = ({ setActivePanel, id }) => {
  const user = useContext(StoreContext).users;
  const navigate = useNavigate();
  let location = useLocation();

  const eyesProblems = {
    myopia: false,
    astigmatism: false,
    glaucoma: false,
    hyperopia: false,
    cataract: false,
    computerFatigue: false,
    other: "",
  };

  const [otherInputVisible, setOtherInputVisible] = useState(false);
  const [mainEyesProblemState, setMainEyesProblemState] =
    useState(eyesProblems);
  const [screenTime, setScreenTime] = useState("");
  const [darkAdaptation, setDarkAdaptation] = useState("");
  const [doubleVision, setDoubleVision] = useState("");
  const [hyperSense, setHyperSense] = useState("");
  const [eyeVeil, setEyeVeil] = useState("");
  const [readFatigue, setReadFatigue] = useState("");
  const [dryEyes, setDryEyes] = useState("");
  const [templePain, setTemplePain] = useState("");
  const [blackout, setBlackout] = useState("");
  const [redEyes, setRedEyes] = useState("");
  const [farFocus, setFarFocus] = useState("");
  const questionArray = [
    screenTime,
    darkAdaptation,
    doubleVision,
    hyperSense,
    eyeVeil,
    readFatigue,
    dryEyes,
    templePain,
    blackout,
    redEyes,
    farFocus,
  ];
  if (questionArray.some((el) => el === "")) {
    console.log("Please fill all");
  }
  let questionObject = {
    screenTime,
    darkAdaptation,
    doubleVision,
    hyperSense,
    eyeVeil,
    readFatigue,
    dryEyes,
    templePain,
    blackout,
    redEyes,
    farFocus,
  };
  console.log(questionObject, mainEyesProblemState);

  const sendQuizResult = async () => {
    console.log(questionArray);
    if (questionArray.some((el) => el === "")) {
      return console.log("Please fill all");
    }

    console.log(config.api.postStartingTestLink);
    const { data } = await axios.post(
      config.api.postStartingTestLink,
      {
        mainEyesProblem: mainEyesProblemState,
        additionalEyesProblem: questionObject,
        vk_user_id: user.data.vk_user_id,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    window.location.reload();
  };

  return (
    <Panel id={id}>
      <PanelHeader>Тест</PanelHeader>
      <FormLayout>
        <Group>
          <FormItem
            top="Есть ли у вас нарушение зрения. Выберите все подходящие варианты"
            status={
              Object.values(mainEyesProblemState).some((el) => el != false)
                ? "valid"
                : "error"
            }
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Checkbox
              name="myopia"
              value={mainEyesProblemState.myopia}
              checked={mainEyesProblemState.myopia}
              onChange={(e) => {
                setMainEyesProblemState((prev) => {
                  let newState = { ...prev };
                  if (newState.myopia === true) {
                    newState.myopia = false;
                    return newState;
                  }
                  newState.myopia = e.target.checked;
                  newState.hyperopia = !e.target.checked;
                  return newState;
                });
              }}
            >
              Близорукость
            </Checkbox>
            <Checkbox
              name="astigmatism"
              value={mainEyesProblemState.astigmatism}
              onClick={(e) => {
                setMainEyesProblemState((prev) => {
                  let newState = { ...prev };
                  console.log(e.target.checked);
                  newState.astigmatism = e.target.checked;
                  return newState;
                });
              }}
            >
              Астигматизм
            </Checkbox>
            <Checkbox
              name="glaucoma"
              value={mainEyesProblemState.glaucoma}
              onClick={(e) => {
                setMainEyesProblemState((prev) => {
                  let newState = { ...prev };
                  console.log(e.target.checked);
                  newState.glaucoma = e.target.checked;
                  return newState;
                });
              }}
            >
              Глаукома
            </Checkbox>
            <Checkbox
              name="hyperopia"
              checked={mainEyesProblemState.hyperopia}
              value={mainEyesProblemState.hyperopia}
              onChange={(e) => {
                setMainEyesProblemState((prev) => {
                  let newState = { ...prev };
                  if (newState.hyperopia === true) {
                    newState.hyperopia = false;
                    return newState;
                  }
                  newState.hyperopia = e.target.checked;
                  newState.myopia = !e.target.checked;
                  return newState;
                });
              }}
            >
              Дальнозоркость
            </Checkbox>
            <Checkbox
              name="cataract"
              value={mainEyesProblemState.cataract}
              onClick={(e) => {
                setMainEyesProblemState((prev) => {
                  let newState = { ...prev };
                  console.log(e.target.checked);
                  newState.cataract = e.target.checked;
                  return newState;
                });
              }}
            >
              Катаракта
            </Checkbox>
            <Checkbox
              name="computerFatigue"
              value={mainEyesProblemState.computerFatigue}
              onClick={(e) => {
                setMainEyesProblemState((prev) => {
                  let newState = { ...prev };
                  console.log(e.target.checked);
                  newState.computerFatigue = e.target.checked;
                  return newState;
                });
              }}
            >
              Усталость глаз от компьютера
            </Checkbox>
            <Checkbox
              name="other"
              // value={back}
              onClick={(e) => {
                if (e.target.checked === true) {
                  setOtherInputVisible(true);
                } else {
                  setOtherInputVisible(false);
                }
              }}
            >
              Другое
            </Checkbox>
            <Input
              value={mainEyesProblemState.other}
              onChange={(e) => {
                setMainEyesProblemState((prev) => {
                  let newState = { ...prev };
                  console.log(e.target.checked);
                  newState.other = e.target.value;
                  return newState;
                });
              }}
              style={{
                display: `${otherInputVisible === true ? "block" : "none"}`,
              }}
            />
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Сколько времени вы проводите перед экраном в течение дня:"
            // status={question2 ? "valid" : "error"}
            status={screenTime !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            // value={screenTime}
            onChange={(e) => setScreenTime(e.target.value)}
          >
            <Radio name="screenTime" value="1-2">
              1-2 часа
            </Radio>
            <Radio name="screenTime" value="2-4">
              2-4 часа
            </Radio>
            <Radio name="screenTime" value="4-6">
              4-6 часов
            </Radio>
            <Radio name="screenTime" value="6+">
              больше 6 часов
            </Radio>
          </FormItem>
        </Group>
        <Div>
          <Text style={{ textAlign: "center" }} weight="semibold">
            Отметьте насколько выражены следующие симптомы:
          </Text>
        </Div>

        <Group>
          <FormItem
            top="Снижение зрения в сумерках (глаза тяжело адоптируются в плохо освещенном помещении)"
            status={darkAdaptation !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setDarkAdaptation(e.target.value)}
          >
            <Radio value={0} name="darkAdaptation">
              Не беспокоит
            </Radio>
            <Radio value={1} name="darkAdaptation">
              Бывает иногда
            </Radio>
            <Radio value={2} name="darkAdaptation">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Бывает ли двоение в глазах"
            status={doubleVision !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setDoubleVision(e.target.value)}
          >
            <Radio value={0} name="doubleVision">
              Не беспокоит
            </Radio>
            <Radio value={1} name="doubleVision">
              Бывает иногда
            </Radio>
            <Radio value={2} name="doubleVision">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Повышенная чувствительность к свету"
            status={hyperSense !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setHyperSense(e.target.value)}
          >
            <Radio value={0} name="hyperSense">
              Не беспокоит
            </Radio>
            <Radio value={1} name="hyperSense">
              Бывает иногда
            </Radio>
            <Radio value={2} name="hyperSense">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Появление пелены или тумана перед глазами"
            status={eyeVeil !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setEyeVeil(e.target.value)}
          >
            <Radio value={0} name="eyeVeil">
              Не беспокоит
            </Radio>
            <Radio value={1} name="eyeVeil">
              Бывает иногда
            </Radio>
            <Radio value={2} name="eyeVeil">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Глаза быстро устают при чтении"
            status={readFatigue !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setReadFatigue(e.target.value)}
          >
            <Radio value={0} name="readFatigue">
              Не беспокоит
            </Radio>
            <Radio value={1} name="readFatigue">
              Бывает иногда
            </Radio>
            <Radio value={2} name="readFatigue">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Ощущение песка в глазах или сухость глаз"
            status={dryEyes !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setDryEyes(e.target.value)}
          >
            <Radio value={0} name="dryEyes">
              Не беспокоит
            </Radio>
            <Radio value={1} name="dryEyes">
              Бывает иногда
            </Radio>
            <Radio value={2} name="dryEyes">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Давящие боль в области висков или лба"
            status={templePain !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setTemplePain(e.target.value)}
          >
            <Radio value={0} name="templePain">
              Не беспокоит
            </Radio>
            <Radio value={1} name="templePain">
              Бывает иногда
            </Radio>
            <Radio value={2} name="templePain">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="'Мурашки' и потемнение в глазах"
            status={blackout !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setBlackout(e.target.value)}
          >
            <Radio value={0} name="blackout">
              Не беспокоит
            </Radio>
            <Radio value={1} name="blackout">
              Бывает иногда
            </Radio>
            <Radio value={2} name="blackout">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Бывает ли что глаза воспаляются, краснеют"
            status={redEyes !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setRedEyes(e.target.value)}
          >
            <Radio value={0} name="redEyes">
              Не беспокоит
            </Radio>
            <Radio value={1} name="redEyes">
              Бывает иногда
            </Radio>
            <Radio value={2} name="redEyes">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="При работе за компьютером или чтении книги трудно фокусировать взгляд на предметах расположенных далеко"
            status={farFocus !== "" ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
            onChange={(e) => setFarFocus(e.target.value)}
          >
            <Radio value={0} name="farFocus">
              Не беспокоит
            </Radio>
            <Radio value={1} name="farFocus">
              Бывает иногда
            </Radio>
            <Radio value={2} name="farFocus">
              Сильно беспокоит
            </Radio>
          </FormItem>
        </Group>

        <FormItem>
          {/* <Button size="l" stretched onClick={handleForm} > */}
          <Button size="l" stretched onClick={sendQuizResult}>
            Отправить
          </Button>
        </FormItem>
      </FormLayout>
    </Panel>
  );
};
