import {
  Button,
  Card,
  CardGrid,
  Panel,
  PanelHeader,
  PanelHeaderBack,
} from "@vkontakte/vkui";
import YouTube from "react-youtube";
import { videoToID } from "../utils/YoutubeLinkToID";

export const OneExercisePanel = ({
  setActivePanel,
  id,
  vkData,
  data,
  setActiveExercise,
}) => {
  const { innerWidth: width, innerHeight: height } = window;
  const opts = {
    height: (width - 32) * 0.6,
    width: width - 32,
  };
  return (
    <Panel id="OneExercisePanel">
      <PanelHeader
        onClick={() => setActivePanel("ExerciseListPanel")}
        left={<PanelHeaderBack />}
      >
        {[
          "additional",
          "myopia",
          "cataract",
          "hyperopia",
          "glaucoma",
          "astigmatism",
        ].includes(data.type)
          ? `Упражнение ${data.title}`
          : `Рецепт`}
        {/* {data.title ? data.title : ""} */}
      </PanelHeader>

      <CardGrid size="l">
        {/* <h1>Тип упражнения: {data.type}</h1> */}
        <Card mode="shadow">
          <YouTube videoId={videoToID(data.link)} opts={opts} />
          {/*<div style={{height: 200}}></div>*/}
        </Card>
      </CardGrid>
    </Panel>
  );
};
