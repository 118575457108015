// const appUrl = "http://andreytren.stn21f.ru/";
// const appUrl = "http://localhost:5002/";
const appUrl = "https://andreytren.stn21f.ru/";

export const config = {
  vkStyle: {
    avatarSize: 64,
  },
  api: {
    paymentLink: appUrl + "api/v1/payment",
    postStartingTestLink: appUrl + "api/v1/starting-quiz",
    userDBdataLink: appUrl + "api/v1/user",
    getChatLinkUrl: appUrl + "api/v1/user/chat",
    getLastPostUrl: appUrl + "api/v1/last-post",
  },
  VK_GROUP_ID: 191432404,
  APP_ADMINS_ARRAY: [2003432, 27793512, 265527734, 52778141],
};
