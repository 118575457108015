import { Panel, PanelHeader, PanelHeaderBack } from "@vkontakte/vkui";
import { ExerciseListElement } from "../components/ExerciseListElement";
import { EyesProblemInfoListElement } from "./EyesProblemInfoListElement";

export const ExerciseListPanel = ({
  setActivePanel,
  exercises_pool,
  setActiveExercise,
  user,
}) => {
  const goMain = () => {
    return setActivePanel("AccountPanel");
  };

  return (
    <Panel id="ExerciseListPanel">
      <PanelHeader left={<PanelHeaderBack onClick={goMain} />}>
        Все уроки
      </PanelHeader>

      <ExerciseListElement
        exerciseTitle={user?.data.current_eyes_problem_video?.title}
        exerciseData=""
        eyesProblemInfoExercise={user?.data.current_eyes_problem_video}
        setActivePanel={setActivePanel}
        setActiveExercise={setActiveExercise}
        title={"Упражнение"}
      />

      {exercises_pool.map((el, idx) => (
        <ExerciseListElement
          key={el?._id}
          exerciseTitle={el?.title}
          setActivePanel={setActivePanel}
          setActiveExercise={setActiveExercise}
          exerciseData={el}
          user={user}
          title={`Рецепт ${idx + 1}`}
        />
      ))}
    </Panel>
  );
};
