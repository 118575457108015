import { Icon56UsersOutline } from "@vkontakte/icons";
import {
  Button,
  Div,
  FormItem,
  FormLayout,
  Group,
  Input,
  Placeholder,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Text,
  Link,
} from "@vkontakte/vkui";
import axios from "axios";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { config } from "../config";
import { StoreContext } from "../store/Store";

export const ProlongPanel = ({ setActivePanel, id, vkData }) => {
  //   const [phone, setPhone] = useState(dbData.phone);
  const [phone, setPhone] = useState("+77777777777");
  const [allowed, setAllowed] = useState(true);
  const [month, setMonth] = useState(1);
  const [tarif, setTarif] = useState(2000);
  const [resultSum, setResultSum] = useState(tarif);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useContext(StoreContext).users;

  //Func
  const monthHandler = (monthCount) => {
    let discount = 0;
    // if (monthCount === 3) discount = (15 / 100) * tarif * monthCount;
    if (monthCount === 3) discount = (30 / 100) * tarif * monthCount;
    if (monthCount === 6) discount = (30 / 100) * tarif * monthCount;
    if (monthCount === 12) discount = (50 / 100) * tarif * monthCount;
    setMonth(monthCount);
    setResultSum(monthCount * tarif - discount);
  };

  const onPaymentButtonText = `Продлить за ${resultSum} ₽ на ${month} мес.`;
  const discountText =
    month > 1 &&
    `Стоимость ${month} месяцев стоит ${
      month * tarif
    } рублей, но с учетом скидки в ${
      ((tarif * month - resultSum) / (tarif * month)) * 100
    }% вам покупка обойдется в ${resultSum} руб. `;

  const goMain = () => {
    console.log(window.location.pathname);
    console.log(location);
    if (location.pathname === "/") {
      return setActivePanel("AccountPanel");
    }
    if (location.pathname === "/payment") {
      return navigate("/account");
    }
    if (location.pathname === "/account") {
      return setActivePanel("AccountPanel");
    }
  };

  const onPaymentHandler = async () => {
    await axios
      .post(
        config.api.paymentLink,
        {
          phone: +phone.replace(/\D+/g, ""),
          vk_user_id: user.data.id ?? user.data.vk_user_id,
          first_name: user.data.first_name,
          last_name: user.data.last_name,
          group_id: "dbData.group_id",
          tariff: "club3_" + "tren",
          fio: user.data.first_name + " " + user.data.last_name,
          club: "dbData.is_cluber",
          month: month,
          price: resultSum,
          active: user.data?.active === true ? true : false,
        },
        { headers: { "Content-Type": "application/json" } }
      )

      // .then((res) => (window.top.location.href = "https://vk.com/app8013570"))

      .then((res) => (window.top.location.href = res.data.paymentLink))
      .catch((e) => console.log(e));
  };
  if (allowed) {
    return (
      //   <Panel id={id}>
      <Panel id="ProlongPanel">
        <PanelHeader left={<PanelHeaderBack onClick={goMain} />}>
          Продление тренажера
        </PanelHeader>
        <Group>
          <FormLayout>
            <FormItem
              top="Телефон"
              status={phone.replace(/\D+/g, "").length > 10 ? "valid" : "error"}
              bottom={
                phone.replace(/\D+/g, "").length > 10
                  ? ""
                  : "Пожалуйста, введите свой телефон"
              }
            >
              <Input
                type="phone"
                name="phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </FormItem>
            <FormItem>
              <Placeholder
                // icon={<Icon56UsersOutline />}
                // header={"text.header"}
                action={
                  <>
                    <Div className="month_wrap">
                      <Button
                        size="m"
                        mode={month === 1 ? "commerce" : "outline"}
                        // onClick={() => setMonth(1)}
                        onClick={() => monthHandler(1)}
                      >
                        1 мес.
                      </Button>
                      <Button
                        size="m"
                        mode={month === 3 ? "commerce" : "outline"}
                        onClick={() => monthHandler(3)}
                      >
                        3 мес.
                      </Button>
                      <Button
                        size="m"
                        mode={month === 6 ? "commerce" : "outline"}
                        onClick={() => monthHandler(6)}
                      >
                        6 мес.
                      </Button>
                      <Button
                        size="m"
                        mode={month === 12 ? "commerce" : "outline"}
                        onClick={() => monthHandler(12)}
                      >
                        12 мес.
                      </Button>
                    </Div>

                    {month === 3 ? (
                      <Text>
                        Стоимость 3 месяцев стоит {month * tarif} рублей, но с
                        учетом скидки в <s>15%</s>{" "}
                        {((tarif * month - resultSum) / (tarif * month)) * 100}%
                        вам покупка обойдется в {resultSum} руб.
                      </Text>
                    ) : (
                      <Text>{discountText}</Text>
                    )}

                    {month === 3 ? (
                      user.data?.active === true ||
                      user.data?.active === false ? (
                        <Button onClick={onPaymentHandler} size="m">
                          Продлить за <s>6000</s> {resultSum} ₽ на {month} мес.
                        </Button>
                      ) : (
                        <Button onClick={onPaymentHandler} size="m">
                          Купить за {resultSum} ₽ на {month} мес.
                        </Button>
                      )
                    ) : (
                      <Button onClick={onPaymentHandler} size="m">
                        {onPaymentButtonText}
                      </Button>
                    )}

                    {/* <Text>{discountText}</Text>

                    <Button onClick={onPaymentHandler} size="m">
                      {onPaymentButtonText}
                    </Button> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "30px",
                      }}
                    >
                      <Link
                        target="_blank"
                        href="https://docs.google.com/document/d/1WzC3q6BdD6SX_Qa8XeWkcGzgrlZV6FomZs6t4NFyepg/edit"
                      >
                        Оферта
                      </Link>
                      <Link
                        target="_blank"
                        href="https://docs.google.com/document/d/1wgPeftGWjq-Q0JSBDSppgVYSMcrwBM8yQx_EtDCvSTc/edit"
                      >
                        Политика
                      </Link>
                    </div>
                  </>
                }
              >
                {/* {"text.content"} */}
              </Placeholder>
            </FormItem>
          </FormLayout>
        </Group>
      </Panel>
    );
  } else {
    return (
      <Panel id={id}>
        <PanelHeader left={<PanelHeaderBack onClick={goMain} />}>
          Продление тренажера
        </PanelHeader>
        <Placeholder
          icon={<Icon56UsersOutline />}
          header="У вас закрыты сообщения с группой. Если не открыть сообщения, то вы не получите уведомление об успешной оплате"
          action={
            <Button onClick={"handlerOpen"} size="m">
              Открыть сообщения
            </Button>
          }
        >
          Попробуем еще разок?
        </Placeholder>
      </Panel>
    );
  }
};
